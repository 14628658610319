@import url(https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,700&display=swap&subset=korean);
/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  background-color: #3a63ad;
  color: #3a63ad;
}
.dot-flashing::before {
  background-color: #3a63ad;
  color: #3a63ad;
}
.dot-flashing::after {
  background-color: #3a63ad;
  color: #3a63ad;
}
@keyframes dotFlashing {
  0% {
    background-color: #3a63ad;
  }
  50%,
  100% {
    background-color: #b4c7e7;
  }
}


.nyobkey {
  position: relative;
  overflow: hidden;
}
.nyobkey .ripple {
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  background-color: rgba(150, 150, 150, 0.5);
  transform: scale(0);
  animation: ripple 0.3s linear;
}
@keyframes ripple {
  to {
    transform: scale(2.5);
    opacity: 0;
  }
}

.infinity-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

.numeric-input-borderless input,
input.numeric-input-borderless {
  border: none;
  box-shadow: none !important;
  font-size: 2rem;
  font-weight: 700;
}
.numeric-input-border input,
input.numeric-input-border {
  border: 1px solid #3a63ad;
  box-shadow: none !important;
  font-size: 2rem;
  font-weight: 700;
}
.numeric-input_modified .ant-input-affix-wrapper {
  line-height: 0;
}
.numeric-input-borderless.font-small input,
input.numeric-input-borderless.font-small {
  font-size: 1.5rem;
}

.numeric-keyboard {
  background: none !important;
}
.numeric-keyboard-key {
  font-size: 1.75rem !important;
  font-weight: 700 !important;
  padding: 1rem;
  border-radius: 32px;
}
.numeric-keyboard-key[data-key=enter] {
  font-size: 1rem !important;
  color: #f0f0f0 !important;
  background: #4076d4 !important;
  border-radius: 4px !important;
}

#record-date-picker input.ant-input {
  border: none;
  box-shadow: none;
  outline: none;
  text-align: center;
}
#record-tab-container .ant-tabs,
#record-tab-container .ant-tabs > .ant-tabs-content {
  height: 100%;
}

#exchange-location-input .ant-input {
  border: none;
  border-radius: 10px / 10px;
  padding: 0.38rem 4.2rem 0.38rem 2.7rem;
}
.exchange-location-search .ant-select-selection {
  border: none;
  box-shadow: none;
}

#notice-list .ant-collapse-header {
  color: #3a63ad;
}

#Navigation .Menu-Item {
  border-bottom: none;
  padding: 5px;
  display: flex;
  align-items: center;
}
#Navigation .Icon {
  font-size: 1.5rem;
  margin: 0;
}
#Navigation .IconText {
  line-height: 1rem;
  padding-top: 10px;
}
#Navigation .Link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(170, 176, 187);
}

input::placeholder {
  color: RGB(160, 160, 160);
}
textarea::placeholder {
  color: RGB(160, 160, 160);
}

#gitple-launcher {
  bottom: 50px !important;
  right: 10px !important;
}

#gitple-launcher-icon {
  width: 45px !important;
  height: 45px !important;
}

#gitple-launcher-icon.gitple-open {
  background-size: 30px !important;
}

.ant-layout {
  max-width: 480px;
}


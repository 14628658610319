@import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,700&display=swap&subset=korean");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(170, 176, 187);
}

input::placeholder {
  color: RGB(160, 160, 160);
}
textarea::placeholder {
  color: RGB(160, 160, 160);
}

#gitple-launcher {
  bottom: 50px !important;
  right: 10px !important;
}

#gitple-launcher-icon {
  width: 45px !important;
  height: 45px !important;
}

#gitple-launcher-icon.gitple-open {
  background-size: 30px !important;
}
